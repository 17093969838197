import React from 'react';
import { Link } from 'gatsby';

const FourOhFour: React.FC = () => {
  return (
    <div>
      <h1>For oh for</h1>
      <code style={{ whiteSpace: 'pre' }}>
        {`for(let i = 0; i < 10; i++) {`}
        <br />
        {`  console.log('We fucked up, or maybe you did?');`}
        <br />
        {`}`}
        <br />
      </code>

      <Link to="/">Go home</Link>
    </div>
  );
};

export default FourOhFour;
